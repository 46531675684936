#content h1, #content h2 {
    font-size: 28px;
    font-weight: 600;
}

#content h3 {
    font-size: 24px;
    font-weight: 600;
}

#content h4 {
    font-size: 22px;
    font-weight: 600;
}

#content h5 {
    font-size: 20px;
    font-weight: 600;
}

#content h6 {
    font-size: 18px;
    font-weight: 600;
}

#content p,
#content ul,
#content ol,
#content h2,
#content h3,
#content h4,
#content h5,
#content h6,
#content img {
    margin-bottom: 12px;
}

#content ol {
    list-style: decimal;
    padding-left: 20px;
}

#content ul {
    list-style: circle;
    padding-left: 20px;
}